<template>
  <div>
  <!-- <v-footer absolute=""  color="#0C111F" style="margin-bottom: 0; position: relative;"> -->

   <v-footer color="#002635" height="45px" class="hidden-xs-only">
    <v-row justify="center" class="text-center px-5">
          <v-col cols="12"  class="py-2">
            <span
              style="font-family: RobotoMedium; font-size: 15px; color: #ffffff"
            >
              &copy; Accessrooms 2023 - All rights reserved
            </span>
            &nbsp;<span style="color: #ffffff">|</span> &nbsp;
            <span
              style="font-family: RobotoMedium; font-size: 15px; color: #ffffff"
            >
              Designed & Developed by
              <a
                style="text-decoration: none"
                href="https://accessrooms.com/"
              >
                <span style="color: #f17343">
                  Team Access Rooms
                </span></a
              >
            </span>
          </v-col>
        </v-row>
   </v-footer>
   <v-footer color="#002635" height="90px" class="hidden-sm-and-up">
    <v-row justify="center" class="text-center px-5">
          <v-col cols="12"  class="py-2">
            <span
              style="font-family: RobotoMedium; font-size: 15px; color: #ffffff"
            >
              &copy; Accessrooms 2023 - All rights reserved
            </span>
            &nbsp;<span style="color: #ffffff">|</span> &nbsp;
            <span
              style="font-family: RobotoMedium; font-size: 15px; color: #ffffff"
            >
              Designed & Developed by
              <a
                style="text-decoration: none"
                href="https://accessrooms.com/"
              >
                <span style="color: #f17343">
                  Team Access Rooms
                </span></a
              >
            </span>
          </v-col>
        </v-row>
   </v-footer>
  <!-- </v-footer> -->
</div>
</template>
  <script>
export default {
  data: () => ({
  }),
};
</script>